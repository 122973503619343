import React, { useEffect, useState } from 'react';
import {
  getAudioFile,
  getWordpressPost,
  WordpressPostResult,
} from '../../helpers/apifetcher';
import { Loader } from '../Loader/Loader';
import { Error } from '../Error/Error';

export type PlayerProps = {
  postId: string;
};
export const Player = ({ postId }: PlayerProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [audioSrc, setAudioSrc] = useState<string>('');
  const [wordpressPost, setWordpressPost] =
    useState<WordpressPostResult | null>(null);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    getWordpressPost(postId)
      .then((result) => setWordpressPost(result))
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setError('Greška prilikom aktiviranja aplikacije Čitaj mi.');
      });
  }, [postId]);

  useEffect(() => {
    if (wordpressPost) {
      const { text } = wordpressPost;

      getAudioFile(text)
        .then((result) => {
          setAudioSrc(result);
          setLoading(false);
        })
        .catch(() => {
          setError('Greška prilikom sintetizovanja glasa.');
          setLoading(false);
        });
    }
  }, [wordpressPost]);

  if (loading) return <Loader />;
  if (error) return <Error text={error} />;

  return <audio controls={true} src={audioSrc} />;
};
