import React, { useState } from 'react';
import './App.css';
import { Player } from './components/Palyer/Player';

export const App = () => {
  const element = document.getElementById('textToSpeechRoot');
  const postId = element?.dataset.id;
  const [showReadme, setShowReadme] = useState<boolean>(false);

  return !!postId ? (
    <>
      {showReadme ? (
        <Player postId={postId} />
      ) : (
        <a className={'ReadmeButton'} onClick={() => setShowReadme(true)}>
          Čitaj mi!
        </a>
      )}
    </>
  ) : null;
};
