export const convertHtmlToText = (html: string): string => {
  // Create a new div element
  const tempDivElement = document.createElement('div');

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  const pureText = tempDivElement.textContent || tempDivElement.innerText || '';
  const purifiedText = pureText.replace(/(\r\n|\n|\r)/gm, '');

  // Retrieve the text property of the element
  return purifiedText;
};
