import axios from 'axios';
import { WP_REST_API_Post } from 'wp-types';
import { API_POST_PATH, TEXT_TO_SPEECH_ENDPOINT } from '../contsants';
import { convertHtmlToText } from './html-to-plain-text';

const endpoint = process.env.REACT_APP_WORDPRESS_ENDPOINT || '/';
const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

export type WordpressPostResult = {
  text: string;
  date: string;
  id: number;
};

export const getWordpressPost = (id: string): Promise<WordpressPostResult> =>
  new Promise((resolve, reject) =>
    axios
      .get<WP_REST_API_Post[]>(`${endpoint}${API_POST_PATH}${id}`)
      .then((response) => {
        if (!response.data.length) {
          reject('No wordpress posts found!');
          return;
        }
        const post = response.data[0];
        const { content, modified_gmt, id } = post;
        resolve({
          text: convertHtmlToText(content.rendered),
          date: modified_gmt,
          id,
        });
      })
      .catch((e) => {
        console.error(e);
        reject('Error fetching wordpress post');
      }),
  );

export const getAudioFile = (text: string): Promise<string> => {
  const payload = {
    audioConfig: {
      audioEncoding: 'MP3',
      effectsProfileId: ['small-bluetooth-speaker-class-device'],
      pitch: 0,
      speakingRate: 1,
    },
    input: {
      text,
    },
    voice: {
      languageCode: 'sr-RS',
      name: 'sr-RS-Standard-A',
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${TEXT_TO_SPEECH_ENDPOINT}?key=${apiKey}`, payload)
      .then((response) =>
        resolve(`data:audio/mp3;base64,${response.data.audioContent}`),
      )
      .catch(() => reject('error getting google speech API')),
  );
};
